<template>
	<div id="main-wrapper" class="inner-pages dep-page">
		<div class="section banner-holder">

			<div class="title-holder t-white t-center center-div wow fadeIn">
				<h2>Deposits and Withrawals</h2>
				<p>A Variety of Easy and Convenient options, at absolutely no cost from our side</p>
			</div>
		</div>
		<div class="section dep-content">
			<div class="wrapper">
				<div class="title-holder t-left wow fadeIn t-center">
					<p>Depositing and withdrawing from your trading account has never been so easy! Invest in your trading account by paying no extra fee. We have various reliable deposit and withdrawal options to make your trading journey smooth and easy.</p>
				</div>
				<div class="listing wow fadeIn">
					<div class="info-holder">
						<h3>0% Service fee</h3>
						<p>We do not charge any fees on either deposits or withdrawals</p>
					</div>
					<div class="info-holder">
						<h3>Transactions Safety</h3>
						<p>The funds are kept in Tier-1 Banks and are segregated from the company’s own funds</p>
					</div>
					<div class="info-holder">
						<h3>Quick Processing</h3>
						<p>Deposits and withdrawals with AAAFX are processed instantly</p>
					</div>
					<div class="info-holder">
						<br>
						<h3>$10</h3>
						<p>Minimum deposit amount</p>
					</div>
					<div class="info-holder">
						<br>
						<h3>10+</h3>
						<p>Global cturrencies accepted</p>
					</div>
					<div class="info-holder">
						<br>
						<h3>Increased control</h3>
						<p>Mandatory KYC ensures greater security</p>
					</div>
				</div>
			</div>
		</div>
		<div class="section deposits-holder">
			<div class="wrapper">
				<div class="title-holder t-center wow fadeIn">
					<h3>WITHDRAWALS</h3>
					<p>Seamless withdrawals make every trader happy, right? That is what we aspire to do for our clients. Here are some quick and easy options to use for the withdrawal of your hard-earned money within 12 hours.</p>
				</div>
				<div class="title-holder t-left wow fadeIn">
					<p>Steps to withdraw from your AAAFx Live Account: <br>
					<br>
					1. Log into the Client Portal. <br>
					2. Set up a Bank account at ‘Bank setup’. <br>
					3. Click on “Funds” and then “Withdraw”. <br>
					</p>
				</div>
				<div class="deposit-nav">
					<ul>
						<li> <a href="dep-with.html">Deposit</a> </li>
						<li class="active"> <a href="dep-with-with.html">Withdrawals</a> </li>
					</ul>
				</div>
				<div class="table-holder wow fadeIn">
					<table>
						<tr>
							<th>Bank Cards</th>
							<th>Region</th>
							<th>Currencies</th>
							<th>Min. Deposit</th>
							<th>Service Fee</th>
							<th>Processing Time</th>
							<th>&nbsp;</th>
						</tr>
						<tr>
							<td><img src="assets/images/dep1.webp" alt="AAAFx" title="AAAFx" width="105" height="31" class="fluid-img" /> </td>
							<td>World Wide</td>
							<td>EUR, USD, CHF, GBP</td>
							<td>$0</td>
							<td>$0</td>
							<td>INSTANT</td>
							<td><a href="#" class="btn btn-blue">FUND</a> </td>
						</tr>
					</table>
				</div>
				<div class="table-holder wow fadeIn">
					<table>
						<tr>
							<th>Bank Wire</th>
							<th>Region</th>
							<th>Currencies</th>
							<th>Min. Deposit</th>
							<th>Service Fee</th>
							<th>Processing Time</th>
							<th>&nbsp;</th>
						</tr>
						<tr>
							<td><img src="assets/images/dep2.webp" alt="AAAFx" title="AAAFx" width="94" height="28" class="fluid-img" /> </td>
							<td>World Wide</td>
							<td>USD</td>
							<td>$0</td>
							<td>$0</td>
							<td>2-3 BUSINESS DAYS</td>
							<td><a href="#" class="btn btn-blue">FUND</a> </td>
						</tr>
						<tr>
							<td><img src="assets/images/dep3.webp" alt="AAAFx" title="AAAFx" width="98" height="25" class="fluid-img" /> </td>
							<td>Europe</td>
							<td>EUR (SEPA Only)</td>
							<td>$0</td>
							<td>$0</td>
							<td>2-3 BUSINESS DAYS</td>
							<td><a href="#" class="btn btn-blue">FUND</a> </td>
						</tr>
						<tr>
							<td><img src="assets/images/dep4.webp" alt="AAAFx" title="AAAFx" width="43" height="31" class="fluid-img" /> </td>
							<td>South East Asia</td>
							<td>MY, TH, ID , PH, VN</td>
							<td>$0</td>
							<td>$0</td>
							<td>INSTANT</td>
							<td><a href="#" class="btn btn-blue">FUND</a> </td>
						</tr>
						<tr>
							<td><img src="assets/images/dep5.webp" alt="AAAFx" title="AAAFx" width="57" height="43" class="fluid-img" /> </td>
							<td>Latin America</td>
							<td>BR, CL, CR, EC, SV, MX, PA, PE</td>
							<td>$0</td>
							<td>$0</td>
							<td>INSTANT</td>
							<td><a href="#" class="btn btn-blue">FUND</a> </td>
						</tr>
						<tr>
							<td><img src="assets/images/dep6.webp" alt="AAAFx" title="AAAFx" width="84" height="23" class="fluid-img" /> </td>
							<td>Mexico</td>
							<td>MXN</td>
							<td>$0</td>
							<td>$0</td>
							<td>INSTANT</td>
							<td><a href="#" class="btn btn-blue">FUND</a> </td>
						</tr>
					</table>
				</div>
				<div class="table-holder wow fadeIn">
					<table>
						<tr>
							<th>Bank Wire</th>
							<th>Region</th>
							<th>Currencies</th>
							<th>Min. Deposit</th>
							<th>Service Fee</th>
							<th>Processing Time</th>
							<th>&nbsp;</th>
						</tr>
						<tr>
							<td><img src="assets/images/e1.webp" alt="AAAFx" title="AAAFx" width="81" height="37" class="fluid-img" /> </td>
							<td>Latin America</td>
							<td>CL, CR, EC, MX, PE</td>
							<td>$0</td>
							<td>$0</td>
							<td>INSTANT</td>
							<td><a href="#" class="btn btn-blue">FUND</a> </td>
						</tr>
						<tr>
							<td><img src="assets/images/e2.webp" alt="AAAFx" title="AAAFx" width="129" height="22" class="fluid-img" /> </td>
							<td>East Africa</td>
							<td>TAS, KES, MTN</td>
							<td>$0</td>
							<td>$0</td>
							<td>2-3 BUSINESS DAYS</td>
							<td><a href="#" class="btn btn-blue">FUND</a> </td>
						</tr>
						<tr>
							<td><img src="assets/images/e3.webp" alt="AAAFx" title="AAAFx" width="126" height="47" class="fluid-img" /> </td>
							<td>Global</td>
							<td>ETH</td>
							<td>$0</td>
							<td>$0</td>
							<td>INSTANT</td>
							<td><a href="#" class="btn btn-blue">FUND</a> </td>
						</tr>
						<tr>
							<td><img src="assets/images/e3.webp" alt="AAAFx" title="AAAFx" width="126" height="47" class="fluid-img" /> </td>
							<td>Global</td>
							<td>BTC</td>
							<td>$0</td>
							<td>$0</td>
							<td>INSTANT</td>
							<td><a href="#" class="btn btn-blue">FUND</a> </td>
						</tr>
						<tr>
							<td><img src="assets/images/e3.webp" alt="AAAFx" title="AAAFx" width="126" height="47" class="fluid-img" /> </td>
							<td>Global</td>
							<td>USDT</td>
							<td>$0</td>
							<td>$0</td>
							<td>INSTANT</td>
							<td><a href="#" class="btn btn-blue">FUND</a> </td>
						</tr>
						<tr>
							<td><img src="assets/images/e4.webp" alt="AAAFx" title="AAAFx" width="59" height="21" class="fluid-img" /> </td>
							<td>Worldwide</td>
							<td>EUR, USD, JPY, CHF, GBP, AUD</td>
							<td>$0</td>
							<td>$0</td>
							<td>2-3 BUSINESS DAYS</td>
							<td><a href="#" class="btn btn-blue">FUND</a> </td>
						</tr>
						<tr>
							<td><img src="assets/images/e5.webp" alt="AAAFx" title="AAAFx" width="154" height="18" class="fluid-img" /> </td>
							<td>Worldwide</td>
							<td>MY, TH, ID, PH, VN</td>
							<td>$0</td>
							<td>$0</td>
							<td>INSTANT</td>
							<td><a href="#" class="btn btn-blue">FUND</a> </td>
						</tr>
					</table>
				</div>
				<div class="title-holder t-left wow fadeIn">
					<p>Additional Information: <br><br>
					1.	All withdrawals will be processed via the method which was used to make a deposit in the trading account.
					<br><br>
					2.	No transaction fee is charged by AAAFx either on deposits or on withdrawals.
					<br><br>
					3.	We reimburse the transaction cost incurred by you up to 5% of your deposit amount, into your trading account.
					</p>
				</div>
				<div class="btn-holder t-center wow fadeIn">
					<a href="#" class="btn btn-yellow">OPEN LIVE ACCOUNT</a>
					<a href="#" class="btn btn-blue">OPEN DEMO ACCOUNT</a>
				</div>
			</div>
		</div>
	</div>
</template>